import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMeta from "vue-meta";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD2EWlAUjTrF39nLeKHaH8FnOjiXNJsbS8",
  // authDomain: "boat-charter-ca246.firebaseapp.com",
  projectId: "boat-charter-ca246",
  storageBucket: "boat-charter-ca246.appspot.com",
  messagingSenderId: "97299459589",
  appId: "1:97299459589:web:e731e75d948e376732b66c",
  measurementId: "G-3DKFLEB3YP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);

Vue.config.productionTip = false;

Vue.use(VueMeta);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
