
import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class ScrollDownBtn extends Vue {
  scrollTo() {
    window.scrollTo({
      behavior: "smooth",
      top: window.innerHeight,
    });
  }
}
