import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    blockedDates: [],
    alert: null,
  },
  getters: {},
  mutations: {
    SET_ALERT(state, alert) {
      state.alert = alert;
    },
  },
  actions: {},
  modules: {},
});
