import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { onAuthStateChanged, getAuth } from "firebase/auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/our-boats",
    name: "our boats",
    component: () =>
      import(/* webpackChunkName: "our boats" */ "../views/BoatView.vue"),
  },
  {
    path: "/our-charters",
    name: "our charters",
    component: () =>
      import(
        /* webpackChunkName: "our charters" */ "../views/ChartersView.vue"
      ),
  },
  {
    path: "/contact-us",
    name: "contact us",
    component: () =>
      import(/* webpackChunkName: "contact us" */ "../views/ContactView.vue"),
  },
  {
    path: "/payment",
    name: "deposit payment",
    component: () =>
      import(/* webpackChunkName: "payment" */ "../views/PaymentView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    beforeEnter: (to, from, next) => {
      const auth = getAuth();
      // TODO: Add this to all routes that require authentication
      onAuthStateChanged(auth, (user) => {
        if (user) {
          next();
        } else {
          next("/");
        }
      });
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/AdminView.vue"),
  },
  {
    path: "/charter/:id",
    name: "Charter",
    component: () =>
      import(/* webpackChunkName: "charter" */ "../views/CharterView.vue"),
  },
  {
    path: "/who-we-are",
    name: "who we are",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "who we are" */ "../views/AboutView.vue"),
  },
  {
    path: "*",
    component: () =>
      import(
        /* webpackChunkName: "404" */ "../components/generic/404-component.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    (document.getElementById("app") as Element).scrollIntoView({
      behavior: "smooth",
    });
  },
});

export default router;
