import { render, staticRenderFns } from "./scroll-down-button.component.vue?vue&type=template&id=5cae254e&scoped=true&"
import script from "./scroll-down-button.component.vue?vue&type=script&lang=ts&"
export * from "./scroll-down-button.component.vue?vue&type=script&lang=ts&"
import style0 from "./scroll-down-button.component.vue?vue&type=style&index=0&id=5cae254e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cae254e",
  null
  
)

export default component.exports