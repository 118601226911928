
import { Vue, Component } from "vue-property-decorator";
import AboutSection from "@/components/about-section-component.vue";
import ScrollDownBtn from "@/components/generic/scroll-down-button.component.vue";

@Component({
  components: {
    AboutSection,
    ScrollDownBtn,
  },
})
export default class HomeComponent extends Vue {
  scrollTo() {
    window.scrollTo({
      behavior: "smooth",
      top: window.innerHeight,
    });
  }
}
