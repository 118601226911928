
import { Vue, Component, Watch } from "vue-property-decorator";

@Component({})
export default class extends Vue {
  @Watch("$store.state.alert") onAlertChange() {
    setTimeout(() => {
      this.clearAlert();
    }, 3000);
  }

  clearAlert(): void {
    this.$store.commit("SET_ALERT", null);
  }
}
