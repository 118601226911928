
import "animate.css";
import { Vue, Component } from "vue-property-decorator";
import AlertsComponent from "@/components/generic/alerts.component.vue";
import FooterComponent from "@/components/generic/footer.component.vue";

@Component({
  components: {
    AlertsComponent,
    FooterComponent,
  },
  metaInfo: {
    title: "Ribbons On The River",
    titleTemplate:
      "%s | Personalized, luxury, unforgettable boating experience",
    meta: [
      {
        name: "description",
        content:
          "Welcome to Ribbons On The River, our mission is to provide you with a personalized, luxury and unforgettable boating chartering experience.",
      },
      {
        name: "keywords",
        content:
          "boat charter, river boat charter, london river boat charter, london boat charter, thames boat charter, thames river boat charter, boat charter thames london, boat charter thames, boat charter london, luxury boat charter, luxury boat charter london, luxury boat charter thames, luxury boat charter thames london, luxury boat charter london thames, luxury boat charter thames l",
      },
      {
        name: "og:title",
        content:
          "Ribbons On The River |  Personalized, luxury, unforgettable boating experience",
      },
      {
        name: "og:description",
        content:
          "Welcome to Ribbons On The River, our mission is to provide you with a personalized, luxury and unforgettable boating chartering experience",
      },
    ],
  },
})
export default class App extends Vue {
  active = false;

  handleScroll(): void {
    const element = window.document.getElementById("header");
    if (window.pageYOffset > 80) {
      element?.classList.add("scrolling");
    } else {
      element?.classList.remove("scrolling");
    }
  }

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }
}
